<template>
  <div>
    <b-sidebar
      id="sidebar-withdrawal-request"
      sidebar-class="sidebar-lg"
      :visible="withdrawalRequestSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateWithdrawalRequestSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Solicitar saque</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12">
              <div class="text-sm-left font-italic text-danger">
                <b-row>
                  <b-col cols="12">
                    <span>
                      A solicitação será criada com todo o saldo disponível no
                      momento.
                    </span>
                  </b-col>
                  <br />
                  <template v-if="isEmployee">
                    <b-col v-if="employeePaymentsInfo" cols="12" class="mt-1">
                      <span>
                        Após solicitado, seu pagamento será depositado dia
                        {{ employeePaymentsInfo.payment_deadline }}. Se não for
                        um dia útil, será depositado no próximo dia útil.
                      </span>
                    </b-col>
                    <b-col v-if="employeePaymentsInfo" cols="12" class="mt-1">
                      <span>
                        Caso você solicite após o dia
                        {{ employeePaymentsInfo.payment_deadline }}, ele será
                        depositado após
                        {{ employeePaymentsInfo.deadline_new_payments }} dias
                        úteis.
                      </span>
                    </b-col>
                  </template>
                  <template v-else>
                    <b-col cols="12" class="mt-1">
                      <span v-if="presentBillingDate" class="mt-3">
                        Comissões serão lançadas até o dia
                        {{ presentBillingDate.nextDate | dateToStr }}.
                      </span>
                    </b-col>
                    <b-col v-if="paymentDeadline" cols="12" class="mt-1">
                      <span>
                        Após solicitado, seu pró-labore será depositado dia
                        {{ paymentDeadline.consultant_payment_deadline }}. Se
                        não for um dia útil, será depositado no próximo dia
                        útil.
                      </span>
                    </b-col>
                    <b-col v-if="paymentDeadline" cols="12" class="mt-1">
                      <span>
                        Caso você solicite após o dia
                        {{ paymentDeadline.consultant_payment_deadline }}, o pró-labore
                        será depositado após {{ paymentDeadline.days }} dias
                        úteis.
                      </span>
                    </b-col>
                  </template>
                </b-row>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-2">
              <b-form-group label="Valor" label-for="withdrawal-amount">
                <b-form-input
                  id="withdrawal-amount"
                  v-money="moneyFormat"
                  disabled
                  :value="maxAmount"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="isDocumentRequired">
            <b-col cols="12" md="12" class="mt-1">
              <b-form-group
                label="Nota fiscal (apenas PDF)"
                label-for="withdrawal-document"
              >
                <b-form-file
                  :disabled="saving"
                  id="withdrawal-document"
                  accept="application/pdf"
                  placeholder="Clique para anexar..."
                  v-model="document"
                  custom-file-label="Buscar"
                  :class="{ 'is-invalid': v$.document.$error }"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.document.required.$invalid">
                    Você deve informar uma nota fiscal em formato PDF.
                  </span>
                  <span v-if="v$.document.maxSize.$invalid">
                    O arquivo deve ter tamanho de até 5MB.
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BCol,
  BRow,
  BFormInput,
} from "bootstrap-vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import { floatToMoney, moneyToFloat } from "@/helpers/converters";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import MoneyInput from "@/modules/shared/components/MoneyInput";
import { VMoney } from "v-money";
import { getBRLFormat } from "@/helpers/formatting";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import * as accountTypes from "@/modules/account/store/types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BCol,
    BRow,
    BFormInput,
    vSelect,
    MoneyInput,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      saving: false,
      document: undefined,
      isDocumentRequired: true,
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      document: {
        required: requiredIf(this.isDocumentRequired),
        maxSize: function (file) {
          if (this.isDocumentRequired) {
            return file && file.size <= 5000000;
          }
          return true;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      withdrawalRequestSidebar: types.WITHDRAWAL_REQUEST_SIDEBAR,
      withdrawalSummary: sharedTypes.WITHDRAWAL_SUMMARY,
      presentBillingDate: sharedTypes.PRESENT_BILLING_DATE,
      paymentDeadline: sharedTypes.BILLING_PAYMENT_DEADLINE,
      employeePaymentsInfo: sharedTypes.EMPLOYEE_PAYMENTS_INFO,
      user: accountTypes.USER,
    }),
    moneyFormat: function () {
      return getBRLFormat();
    },
    maxAmount: function () {
      return floatToMoney(this.withdrawalSummary?.balance) || 0;
    },
    isEmployee: function () {
      return this.user?.is_employee || false;
    },
  },
  methods: {
    floatToMoney,
    ...mapMutations({
      mutateWithdrawalRequestSidebar: types.MUTATE_WITHDRAWAL_REQUEST_SIDEBAR,
    }),
    ...mapActions({
      getWithdrawalSummary: sharedTypes.GET_WITHDRAWAL_SUMMARY,
      saveWithdrawalRequest: types.SAVE_WITHDRAWAL_REQUEST,
      getPresentBillingDate: sharedTypes.GET_PRESENT_BILLING_DATE,
      getPaymentDeadline: sharedTypes.GET_BILLING_PAYMENT_DEADLINE,
      getEmployeePaymentsInfo: sharedTypes.GET_EMPLOYEE_PAYMENTS_INFO,
      getWithdrawalDocumentRequired: types.GET_WITHDRAWAL_DOCUMENT_REQUIRED,
    }),
    onShow() {
      this.getWithdrawalDocumentRequired()
        .then((resp) => {
          if (resp.data) {
            this.isDocumentRequired = resp.data.required;
          }
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao recuperar informações para saque. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      this.getWithdrawalSummary().catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar o saldo para saque. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
      this.getPresentBillingDate().catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar as informações de pagamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
      this.getPaymentDeadline().catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar as informações de pagamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
      this.getEmployeePaymentsInfo().catch((error) => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao recuperar as informações de pagamento. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const payload = {
        document: this.document,
      };
      const request = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        if (value && value !== "") {
          request.append(key, value);
        }
      }
      this.saveWithdrawalRequest(request)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "A solicitação de saque foi salva com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.withdrawalRequestSidebar.saveAction();
          this.mutateWithdrawalRequestSidebar({ visible: false });
          this.$emit("formSaved");
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao salvar a solicitação. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    clear() {
      this.document = undefined;
      this.isDocumentRequired = true;
      this.v$.$reset();
    },
  },
};
</script>
